"use client";
import clsx from "clsx";

import DepthTracker from "shared-components/components/depthTracker";
import FullWidthElement from "shared-components/components/fullWidthElement";

import RevisedRecipeGrid from "@/components/RevisedRecipeGrid";
import Section from "@/components/Section";
import SectionHeader from "@/components/SectionHeader";
import Ad from "@/components/ad";
import HugeRecipeCard from "@/components/brand/EasierEats//components/hugeRecipeCard";
import BrandedRecipeList from "@/components/brand/EasierEats/components/brandedRecipelist";

function BrandedSection({
  className = "",
  name = "",
}: {
  className?: string;
  name?: string;
}) {
  return (
    <SectionHeader
      name={name}
      className={clsx(
        className,
        "border-b-2 border-theme-primary pb-3 text-2xl italic text-theme-secondary print:hidden",
      )}
    />
  );
}

export default function RecipeReaderLogo({
  className = "",
  mainRecipes = [] as Recipe[],
  supplementalRecipes = [] as Recipe[],
}) {
  const allRecipes = [...mainRecipes, ...supplementalRecipes];
  const firstRecipeCardRecipe = allRecipes[0];
  const exploreMoreRecipes = allRecipes.slice(1, 9);
  const secondRecipeCardRecipe = allRecipes[9];
  const latestRecipes = allRecipes.slice(10, 14);

  return (
    <div className={clsx(className, "main-content relative")}>
      <FullWidthElement>
        <Ad
          id="Desktop_MH_HP"
          className="mx-auto mb-8 hidden max-h-[260px] min-h-[100px] min-w-[740px] max-w-[980px] md:block"
        />
        <Ad
          id="Mobile_MH_HP"
          className="mx-auto mb-8 max-h-[290px] min-h-[260px] min-w-[310px] max-w-[336px] md:hidden"
        />
        {secondRecipeCardRecipe && (
          <Section className="py-8" fullWidth>
            <div id="ATD_RD_Desktop_MH_HP" />
            <HugeRecipeCard priority recipe={firstRecipeCardRecipe} />
          </Section>
        )}

        <Ad
          id="Desktop_INR1_HP"
          className="mx-auto mb-8 hidden max-h-[290px] min-h-[100px] min-w-[740px] max-w-[980px] md:block"
        />

        <Section className="py-8">
          <BrandedSection name="Explore More" />
          <RevisedRecipeGrid
            pageType="/"
            gridClasses="grid-col-1 sm:grid-cols-2 md:grid-cols-4"
            recipes={exploreMoreRecipes}
            cardHoverBorder
            trackingName="home-page"
            cardCategoryColor="text-theme-secondary"
            cardTitleFontFace="font-sans"
            cardTitleColor="text-black group-hover:text-theme-primary group-hover:no-underline"
            insertionIndex={4}
            insertionAdComponent={
              <Ad
                id="Mobile_INR1_HP"
                className="mx-auto mb-8 max-h-[610px] min-h-[60px] min-w-[130px] max-w-[336px] md:hidden"
              />
            }
          />
        </Section>

        <Ad
          id="Desktop_INR2_HP"
          className="mx-auto mb-8 hidden max-h-[290px] min-h-[100px] min-w-[740px] max-w-[980px] md:block"
        />
        <Ad
          id="Mobile_INR2_HP"
          className="mx-auto mb-8 max-h-[610px] min-h-[60px] min-w-[130px] max-w-[336px] md:hidden"
        />

        {secondRecipeCardRecipe && (
          <Section className="py-8" fullWidth>
            <HugeRecipeCard recipe={secondRecipeCardRecipe} />
          </Section>
        )}

        <Ad
          id="Desktop_INR3_HP"
          className="mx-auto mb-8 hidden max-h-[290px] min-h-[100px] min-w-[740px] max-w-[980px] md:block"
        />
        <Ad
          id="Mobile_INR3_HP"
          className="mx-auto mb-8 max-h-[610px] min-h-[60px] min-w-[130px] max-w-[336px] md:hidden"
        />

        <Section className="py-8">
          <BrandedSection name="The Latest" />
          <div className="flex justify-between">
            <BrandedRecipeList recipes={latestRecipes} />
            <div className="hidden md:block">
              <Ad
                id="Desktop_SB_RHS_HP"
                className="sticky top-24 flex flex-col items-start xl:h-[610px] xl:w-[170px]"
              />
            </div>
          </div>
        </Section>

        {/* <Ad
            id="Mobile_INR1_HP"
            className="mx-auto max-h-[610px] !min-h-[60px] w-[336px]"
          />

          <Ad
            id="Mobile_EOA_HP"
            className="w-[336px]m mx-auto max-h-[610px] !min-h-[60px]"
          /> */}
        <DepthTracker viewedItem="footer" className="hidden md:block" />
      </FullWidthElement>
    </div>
  );
}
