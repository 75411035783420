"use client";
import clsx from "clsx";

import DepthTracker from "shared-components/components/depthTracker";
import FullWidthElement from "shared-components/components/fullWidthElement";

import RevisedRecipeGrid from "@/components/RevisedRecipeGrid";
import Section from "@/components/Section";
import Ad from "@/components/ad";
import HugeRecipeCard from "@/components/brand/LazyCooking/components/hugeRecipeCard";
import SmallRecipeCard from "@/components/brand/LazyCooking/components/smallRecipeCard";

import BrandedSection from "../components/BrandedSection";

export default function LazyCookingHome({
  className = "",
  mainRecipes = [] as Recipe[],
  supplementalRecipes = [] as Recipe[],
}) {
  const allRecipes = [...mainRecipes, ...supplementalRecipes];
  const firstRecipeCardRecipe = allRecipes[0];
  const recipesOfTheWeek = allRecipes.slice(1, 4);
  const whatsNewRecipes = allRecipes.slice(5, 11);
  const trendingRecipes = allRecipes.slice(11, 19);
  const favoriteRecipes1 = allRecipes.slice(19, 22);
  const favoriteRecipes2 = allRecipes.slice(22, 30);

  return (
    <div className={clsx(className, "main-content relative")}>
      <FullWidthElement>
        <Ad
          id="Desktop_MH_HP"
          className="mx-auto mb-8 hidden max-h-[260px] min-h-[100px] min-w-[740px] max-w-[980px] md:block"
        />

        <Section className="mb-8 bg-theme-secondary bg-opacity-20 py-8">
          {firstRecipeCardRecipe && (
            <div className="mb-12 w-full md:mt-6">
              {/* mobile */}
              <div className="w-full border-b-2 border-dashed border-theme-primary pb-2 md:hidden">
                <FullWidthElement>
                  <SmallRecipeCard priority recipe={firstRecipeCardRecipe} />
                </FullWidthElement>
              </div>
              {/* desktop */}
              <div className="hidden w-full md:block">
                <HugeRecipeCard priority recipe={firstRecipeCardRecipe} />
              </div>
            </div>
          )}

          <Ad
            id="Mobile_MH_HP"
            className="mx-auto mb-8 max-h-[290px] min-h-[260px] min-w-[310px] max-w-[336px] md:hidden"
          />

          <div className="mb-12 flex flex-col gap-y-8">
            <BrandedSection name="Recipes of the Week" />
            <RevisedRecipeGrid
              pageType="/"
              gridClasses="grid-cols-1 md:grid-cols-3"
              recipes={recipesOfTheWeek}
              trackingName="home-page"
              cardAlignment="text-left"
              cardTitleColor="text-black"
            />
          </div>
        </Section>

        <Ad
          id="Desktop_INR1_HP"
          className="mx-auto mb-8 hidden max-h-[290px] min-h-[100px] min-w-[310px] max-w-[980px] md:block"
        />
        <Ad
          id="Mobile_INR1_HP"
          className="mx-auto mb-8 max-h-[610px] min-h-[60px] min-w-[130px] max-w-[336px] md:hidden"
        />

        <Section className="py-8">
          <BrandedSection name="What's New" />
          <RevisedRecipeGrid
            pageType="/"
            round
            recipes={whatsNewRecipes}
            trackingName="home-page"
            cardTitleColor="text-black group-hover:text-theme-primary"
            cardTitleFontFace="font-sans"
            cardCategoryColor="text-theme-primary"
          />
        </Section>

        <Section className="mb-8 py-8">
          <BrandedSection name="Trending Recipes" />
          <RevisedRecipeGrid
            pageType="/"
            recipes={trendingRecipes}
            gridClasses="grid-cols-2 md:grid-cols-4"
            trackingName="home-page"
            cardTitleColor="text-black group-hover:text-theme-primary"
            cardTitleFontFace="font-sans"
            cardCategoryColor="text-theme-primary"
          />
        </Section>

        <Ad
          id="Desktop_INR2_HP"
          className="mx-auto mb-8 hidden max-h-[290px] min-h-[100px] min-w-[310px] max-w-[980px] md:block"
        />
        <Ad
          id="Mobile_INR2_HP"
          className="mx-auto mb-8 max-h-[610px] min-h-[60px] min-w-[130px] max-w-[336px] md:hidden"
        />

        <Section className="py-8">
          <BrandedSection name="Favorites" />
          <RevisedRecipeGrid
            pageType="/"
            gridClasses="grid-cols-1 md:grid-cols-3"
            recipes={favoriteRecipes1}
            trackingName="home-page"
            cardAlignment="text-left"
            cardTitleColor="text-black"
          />
          <RevisedRecipeGrid
            pageType="/"
            gridClasses="grid-cols-2 md:grid-cols-4"
            recipes={favoriteRecipes2}
            trackingName="home-page"
            cardAlignment="text-left"
            cardTitleColor="text-black"
          />
        </Section>

        <DepthTracker viewedItem="footer" className="hidden md:block" />
      </FullWidthElement>
    </div>
  );
}
