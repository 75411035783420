"use client";

import { PropsWithChildren, useEffect, useState, Fragment } from "react";
import { usePathname } from "next/navigation";

import clsx from "clsx";
import { useIntersectionObserver } from "usehooks-ts";
import { v4 as uuidv4 } from "uuid";

import { log } from "shared-lib/helpers/logging/logger";

import { useGlobalState } from "@/context/globalState";

import RoundRecipeCard from "./RoundRecipeCard";
import SquareRecipeCard from "./SquareRecipeCard";

type Props = {
  recipes: Recipe[];
  trackingName?: string;
  gridClasses?: string;
  cardCategoryColor?: string;
  cardCategoryFontFace?: string;
  cardTitleColor?: string;
  cardTitleFontFace?: string;
  cardZoom?: boolean;
  cardAlignment?: string;
  round?: boolean;
  cardClassName?: string;
  cardBoxStyle?: string;
  className?: string;
  cardHoverBorder?: boolean;
  cardHoverBorderColor?: string;
  cardHideCategory?: boolean;
  cardUseTag?: boolean;
  pageType: string;
  priority?: boolean;
  insertionAdComponent?: JSX.Element;
  insertionIndex?: number;
};

export default function RevisedRecipeGrid({
  recipes,
  trackingName = "",
  gridClasses = "grid-cols-2 md:grid-cols-3 lg:grid-cols-6",
  cardBoxStyle = "",
  cardClassName = "",
  cardCategoryColor = "",
  cardCategoryFontFace = "",
  cardTitleColor = "",
  cardTitleFontFace = "",
  cardHoverBorder = false,
  cardHoverBorderColor = "",
  cardZoom = true,
  cardAlignment = "",
  round = false,
  className = "",
  cardHideCategory = false,
  children,
  cardUseTag = false,
  pageType,
  priority = false,
  insertionAdComponent,
  insertionIndex,
}: PropsWithChildren<Props>) {
  const passThrough: any = {
    zoom: cardZoom,
    hideCategory: cardHideCategory,
    useTag: cardUseTag,
  };
  if (cardHoverBorder) {
    passThrough.hoverBorder = cardHoverBorder;
  }
  if (cardHoverBorderColor) {
    passThrough.hoverBorderColor = cardHoverBorderColor;
  }
  if (cardClassName) {
    passThrough.className = cardClassName;
  }
  if (cardBoxStyle) {
    passThrough.boxStyle = cardBoxStyle;
  }
  if (cardCategoryColor) {
    passThrough.categoryColor = cardCategoryColor;
  }
  if (cardCategoryFontFace) {
    passThrough.categoryFontFace = cardCategoryFontFace;
  }
  if (cardTitleColor) {
    passThrough.titleColor = cardTitleColor;
  }
  if (cardTitleFontFace) {
    passThrough.titleFontFace = cardTitleFontFace;
  }
  if (cardAlignment) {
    passThrough.alignment = cardAlignment;
  }

  const [globalState] = useGlobalState();
  const [gridID, setGridID] = useState("");
  const [hasFiredView, setHasFiredView] = useState(false);

  const { isIntersecting: isVisible, ref } = useIntersectionObserver({});
  const pathName = usePathname();
  useEffect(() => {
    if (recipes.length) {
      setHasFiredView(false);
      setGridID(uuidv4());
    }
  }, [recipes]);

  useEffect(() => {
    if (isVisible && !hasFiredView) {
      setHasFiredView(true);

      const viewTrackObj = {
        sub_type: "grid_view",
        original_url: pathName, // remove query params
        referrer: document.referrer,
        experiments: "", // [KVPs for each experiment membership]
        content_type: "recipe",
        page_type: pageType, // '/recipe/[slug]' (slug WILL NOT be replaced with the actual slug)
        location: location, // a name specific to the placement of a grid on the site
        md5: globalState.md5,
        grid_id: gridID, // primary key - unique ID for identifying an exact instance of a grid
        tiles: recipes.map((r, i) => {
          return { index: i, recipe: r.sys.id };
        }),
        // [all cookie info]
      };

      log("grid view should fire", { viewTrackObj });
    }
  }, [
    globalState.md5,
    gridID,
    hasFiredView,
    isVisible,
    trackingName,
    recipes,
    pathName,
    pageType,
  ]);

  const gridItemClicked = (index: number) => {
    const clickTrackObj = {
      sub_type: "grid_click",
      grid_id: gridID, // foreign key - connecting to unique grid view event
      tile_index: index,
    };

    log("grid click should fire", { clickTrackObj });
  };

  return (
    <div
      className={clsx("grid gap-4 px-4 py-4 lg:px-0", gridClasses, className)}
      ref={ref}
    >
      {children}
      {recipes.map((r, i) => (
        <Fragment key={`grid-item-${r.slug}-${i}`}>
          {insertionAdComponent && insertionIndex === i && (
            <div key={`insertion-${i}`}>{insertionAdComponent}</div>
          )}
          {round ? (
            <RoundRecipeCard
              recipe={r}
              key={`recipe-${r.slug}`}
              trackingLabel={`${trackingName}${i}`}
              onClick={() => {
                gridItemClicked(i);
              }}
              priority={priority}
              {...passThrough}
            />
          ) : (
            <SquareRecipeCard
              key={`recipe-${r.slug}`}
              recipe={r}
              onClick={() => {
                gridItemClicked(i);
              }}
              priority={priority}
              {...passThrough}
            />
          )}
        </Fragment>
      ))}
    </div>
  );
}
